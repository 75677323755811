export const faq = [ 
    { 
        key: 1, 
        title: 'What is Vant for Business? ', 
        data: `Vant for Business is an all-in-one web solution offering seamless financial management for business.`, 
        isOpen: false
    }, 
    { 
        key: 2, 
        title: 'How do I sign up for Vant for Business?', 
        data: `You can sign up by visiting business.vant.ng/signup and following the registration process.`, 
        isOpen: false
    }, 
    { 
        key: 3, 
        title: 'Is Vant for Business free to use? ', 
        data: `Yes, signing up is free and features are currently available at no additional cost.`, 
        isOpen: false
    }, 
    { 
        key: 4, 
        title: 'What features are available on Vant for Business?  ', 
        data: `Vant for Business offers payment links, invoicing, bulk payments, product listings, business accounts, team member management, and financial tracking.`, 
        isOpen: false
    }, 
    { 
        key: 5, 
        title: 'Can I receive payments through Vant for Business?', 
        data: `Yes, you can receive payments via payment links, invoices, and direct transfers to your Vant for Business account.`, 
        isOpen: false
    }, 
    { 
        key: 6, 
        title: 'How secure is Vant for Business?', 
        data: `Vant for Business uses top-tier security measures to protect your data and transactions. We protect your data with bank-level encryption. Your data is not shared with 3rd party service providers your permission.`, 
        isOpen: false
    }, 
    { 
        key: 7, 
        title: 'Does Vant for Business support bulk payments?', 
        data: `Yes, you can make multiple payments at once, ideal for payroll, vendor payments, and group payouts.`, 
        isOpen: false
    }, 
    { 
        key: 8, 
        title: 'Can I integrate Vant for Business with other platforms? ', 
        data: `Yes, Vant for Business offers API integrations for seamless business operations.`, 
        isOpen: false
    },  { 
        key: 9, 
        title: 'Is there a mobile app for Vant for Business?', 
        data: `Vant for business currently available only the web.`, 
        isOpen: false
    }, 
    { 
        key: 10, 
        title: 'How can I contact Vant for Business support?', 
        data: `You can reach out via email: support@vantapp.com, or Chat with on WhatsApp: 09135792534`, 
        isOpen: false
    }, 
    { 
        key: 11, 
        title: 'Are there any transaction limits on Vant for Business? ', 
        data: `You can reach out via email: support@vantapp.com, or Chat with on WhatsApp: 09135792534`, 
        isOpen: false
    }, 
    { 
        key: 12, 
        title: 'Can I use Vant for Business for international payments?  ', 
        data: `Currently, Vant for Business supports NGN and USD transactions, with more options coming soon.`, 
        isOpen: false
    }, 
]
