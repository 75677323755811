import { ClipboardText, Lock } from "iconsax-react";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CountdownTimer from "../components/Countdown.js";
import Modal from "../components/Modal.js";
import { PacmanLoader } from "react-spinners";
import CountdownApiTimer from "../components/CountdownApi.js";
import { NumericFormat } from "react-number-format";

const Checkout = () => {
  const [copiedRef, setCopiedRef] = useState(null);
  const [checkModal, setCheckModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { payData, type } = location.state;
  // console.log("Paydata", payData)
  const openCheck = () => {
    setCheckModal(true);
  };

  const closeCheck = () => {
    setCheckModal(false);
  };
  // Function to copy text to the clipboard
  const handleCopy = async (number) => {
    try {
      await navigator.clipboard.writeText(number);
      setCopiedRef(number); // Set copied ref to show feedback
      setTimeout(() => setCopiedRef(null), 2000); // Clear feedback after 2 seconds
    } catch (err) {
      //console.error("Failed to copy:", err);
    }
  };

  //production server
  // const API_BASE_URL = 'https://dev.vantapp.com/api/partner-business';

  //live server
  const API_BASE_URL_LINK =
    "https://api.vantapp.com/api/partner-business/public/payment-link";
  const API_BASE_URL_INVOICE =
    "https://api.vantapp.com/api/partner-business/public/invoice";

  const apiUrl = `${
    type === "link" ? API_BASE_URL_LINK : API_BASE_URL_INVOICE
  }/transaction?reference=${payData?.reference}`;

  const verify = async (e) => {
    try {
      const response = await fetch(apiUrl, {
        method: "GET", // HTTP method
        headers: {
          "Content-Type": "application/json", // Specify JSON format
        },
      });

      const data = await response.json(); // Handle JSON response
      if (data?.data?.status === "successful") {
        navigate("/success", { state: { payData: data } });
      }
      return data;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div classsName="bg-[#fefefe] relative z-10 jost px-4 min-h-screen flex flex-col items-center ">
      <img
        src="/image/vector.png"
        alt="bg"
        className="absolute top-0 left-0 -z-10 h-full w-full object-cover "
      />
      <div className="w-[95%] sm:w-[400px] md:w-[486px] mt-12 mx-auto">
        <div className="bg-[#26ae5f] jost text-white rounded-lg py-5 px-4 relative">
          <img
            src="./image/blob.png"
            alt="blob"
            className="absolute bottom-0 left-[33.33%]"
          />

          <ul>
            <li className="flex justify-between">
              <p className="text-[13px]">Amount</p>
              <img
                src="./image/vantLogoWhite.png"
                alt="blob"
                className="h-[30px] w-[60px]"
              />
            </li>
            <li className="mt-3">
              <p className="text-[20px] font-semibold">
                {" "}
                <NumericFormat
                  value={payData?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </p>
            </li>
            {/* <li className="flex items-center justify-between text-[13px]">
              <p>Fee</p>
              <p>0.00</p>
            </li> */}
            <li className="flex items-center justify-between text-[14px] mt-2">
              <p>currency </p>
              <p>NGN </p>
            </li>
          </ul>
        </div>
        <div className="bg-[#a8faca3d] jost  text-gray-700 rounded-lg py-4 px-3  mt-5">
          <p className="text-[14px] leading-[13px] ">
            Pay into the Account below
          </p>
          <p className="text-[12px] leading-[10px] tracking-tight mt-2 ">
            Copy & transfer money into the account details below
          </p>
          <p className="text-[12px] leading-[10px] tracking-tight mt-2 ">
            Click <span>"I have Paid"</span> when completed
          </p>
          <ul className="mt-6">
            <li className="flex justify-between items-center">
              <p className="text-[12px] leading-[12px]">Bank</p>
              <p className="text-[12px] leading-[12px] font-semibold">
                {payData?.bank} Bank
              </p>
            </li>
            <li className="flex justify-between items-center mt-3">
              <p className="text-[12px] leading-[12px]">Account Number</p>
              <div className="flex items-center gap-1">
                <p className="text-[12px] leading-[12px] font-semibold">
                  {payData?.account_number}
                </p>
                <button
                  onClick={() => handleCopy(payData?.account_number)}
                  className="hover:-translate-y-1  transition-transform ease-in-out "
                >
                  {" "}
                  {copiedRef === payData?.account_number ? (
                    <span className="font-normal leading-[12px] text-[10px]">
                      Copied!
                    </span>
                  ) : (
                    <ClipboardText size={12} />
                  )}
                </button>
              </div>
            </li>
            <li className="flex justify-between items-center mt-3">
              <p className="text-[12px] leading-[12px]">Account Name</p>
              <p className="text-[12px] leading-[12px] font-semibold">
                {payData?.account_name}
              </p>
            </li>
            <li className="flex justify-between items-center mt-3">
              <p className="text-[12px] leading-[12px]">Amount To Pay</p>
              <p className="text-[12px] leading-[12px] font-semibold">
              <NumericFormat
                  value={payData?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </p>
            </li>
          </ul>
        </div>

        <div className=" rounded-lg py-4 px-3  jost mt-5">
          <p className="text-[12px] leading-[12px] text-red-400 text-center ">
            This account is for this transaction only and it expires in{" "}
          </p>
          <span className="mt-3 text-center">
            {" "}
            {payData?.valid_till ? (
              <CountdownTimer targetTime={payData?.valid_till} />
            ) : (
              ""
            )}
          </span>
        </div>

        <button
          onClick={openCheck}
          className="w-full py-[14px] text-center text-white jost bg-[#26ae5f] rounded-[8px] flex items-center justify-center mb-[20px] md:mb-[32px] mt-6"
        >
          <p className="text-sm font-medium leading-[20px] flex items-center gap-1">I have Paid  <NumericFormat
                  value={payData?.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                /></p>
          {/* {isLoading && <ClipLoader color={"white"} size={20} />} */}
        </button>

        <div className=" mt-8 flex items-center justify-center gap-[4px] text-[10px] text-gray-500">
          <Lock size={12} color="#000" /> <p>Powered & Secured by</p>{" "}
          <img
            src="./image/VantLogo.png"
            alt="Logo"
            className=" h-[10px] w-[20px]"
          />
        </div>
      </div>

      <Modal isOpen={checkModal}>
        <div className="inline-block overflow-hidden text-left relative align-bottom transition-all transform bg-[white] rounded-lg shadow-xl w-[90%] sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
          <div className="mt-4 flex flex-col items-center mx-5 jost  ">
            <p className="text-[14px] leading-[13px] jost  text-gray-700  ">
              Verify Payment
            </p>
            <div className="mx-auto mt-8">
              <PacmanLoader color={"#26ae5f"} size={12} />
            </div>

            <div classsName="text-center">
              <p className="text-[14px] leading-[13px] jost  text-center text-gray-400 mt-6 ">
                We're processing your transfer..{" "}
              </p>
              <p className="text-[14px] leading-[13px] jost text-center  text-gray-400  mt-1 ">
                This may take a few minutes.
              </p>
            </div>
            <div>
              <div className="bg-[#26ae5f] mb-7 rounded-lg w-full px-4 py-[6px] flex gap-2 items-center justify-center text-white  mt-9">
                <p className="text-[15px] leading-[13px] jost  ">
                  We appreciate your patience
                </p>

                <span className=" text-center">
                  {" "}
                  {payData?.valid_till && checkModal ? (
                    <CountdownApiTimer
                      targetTime={payData?.valid_till}
                      verifyApi={verify}
                    />
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>

            <button
              onClick={closeCheck}
              className="py-1 px-2 rounded-lg border text-gray-500 text-[14px] mt-3 mb-7"
            >
              See Account Number
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Checkout;
