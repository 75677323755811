import React from "react";

import background from "../src/assets/Shadow.png";
import ar from "../src/assets/AR.jpg";
import frame from "../src/assets/Frame.jpg";
import frame1 from "../src/assets/12.jpg";
import frame2 from "../src/assets/21shot.jpg";
import HERO from "../src/assets/hero1.png";
import Navbar from "./components/navbar";
import { Star } from "iconsax-react";
import Footer from "./components/Footer";
import { Link } from "react-router-dom";
import Partners from "./components/Partners";

const Bussiness = () => {
  return (
    <div>
      <Navbar />
      <div className="px-4 md:px-7 lg:px-10">
        <div className=" p-4  mt-[2.0rem] md:mt-[4.0rem]">
          <div className="flex flex-col gap-7 md:items-center  md:flex-row items-start justify-between">
            <div className="w-full lg:w-[55%]">
              <p className=" text-[36px] lg:text-[64px] text-[#302348] font-bold  leading-[36px] lg:leading-[64px] mb-7">
                Unlock <span className="text-[#3B6896]">Tools</span> To Simplify
                Your Business Finances
              </p>
              <p className="text-[15px] text-[#878787] lg:text-[17px] mb-10 w-full md:w-[80%] ">
                Manage your payments, collections, and transfers effortlessly,
                all from one platform.
              </p>
              <div className="flex items-center gap-8 ">
                <Link
                  to="https://business.vant.ng/login"
                  rel="noreferrer"
                  target="_blank"
                  className="border text-[14px] md:text-[16px]  text-[#3B6896] hover:bg-slate-50 px-4 lg:px-7 py-2 lg:py-3 border-radius rounded-full"
                >
                  Create an Account
                </Link>
                <a
                  href="mailto: support@vantapp.com"
                  className="hover:text-[#878787] text-[14px] md:text-[16px] "
                >
                  Contact Us
                </a>
              </div>
            </div>
            <div className="w-full md:w-[45%]">
              <img
                src={HERO}
                alt=""
                className=" w-full object-cover h-[290px] md:h-[300px] lg:h-[320px] xl:h-[390px] rounded-xl "
              />
            </div>
          </div>
        </div>
        <Partners />
        <div className="text-center px-4">
          <p className="text-[24px] md:text-[32px] xl:text-[46px]">
            Multiple Businesses On{" "}
            <span className="text-[#3B6896]">
              <strong> One Platform</strong>
            </span>
          </p>
          <p className="text-[#878787] text-[15px] lg:text-[20px]">
            Switch between different businesses seamlessly, with consolidated
            financial insight across all.
          </p>
        </div>

        <div className="flex justify-center items-center mt-[60px] lg:mt-[100px] mx-auto">
          <div className="relative">
            <img
              src={background}
              alt=""
              width="1130px"
              className="max-h-[400px]"
            />
            <img
              src={ar}
              alt=""
              width="1160px"
              className="absolute -top-[17px] left-[19px] z-5  max-h-[400px] "
            />
          </div>
        </div>
        <section className="mt-10 md:mt-18 w-full rounded-xl overflow-hidden  bg-[#F9F9F9] px-4 md:px-[28px] lg:px-[42px] xl:px-[86px] py-5 md:py-7 lg:py-9 xl:py-14 ">
          <p className=" text-[24px] md:text-[36px] text-center font-medium">
            Experience Flexibility and scalable Business solution with{" "}
            <span className="text-[#3B6896]">
              <strong>Vant for Business</strong>
            </span>
          </p>
          <div className="flex flex-col lg:flex-row lg:items-center  gap-[40px]  mt-10 bg-[#F9F9F9] w-full">
            <div className="w-full lg:w-[50%]">
              <p className="text-[26px] md:text-[32px] font-semibold lg:text-[56px] leading-[26px] md:leading-[32px] lg:leading-[56px] text-[#302348]">
                Share secured links to receive
                <span className="text-[#3A6C9B]">
                  {" "}
                  payments from any where.
                </span>
              </p>
              <p className="text-[13px] md:text-[18px] text-[#878787]">
                Get paid easily by sharing a secure, custom payment link with
                customers across the globe.
              </p>
            </div>
            <div className="w-full lg:w-[50%] flex justify-center">
              <img
                src={frame}
                alt=""
                className=" h-[260px] md:h-[260px] lg:h-[300px] xl:h-[360px] mx-auto"
              />
            </div>
          </div>
        </section>
        <section className="relative mt-10 md:mt-18 w-full rounded-xl overflow-hidden  bg-[#9CD4C3] px-4 md:px-[28px] lg:px-[42px] xl:px-[86px] py-5 md:py-7 lg:py-9 xl:py-14 ">
          <div className="w-[50%]">
            <p className="text-[20px] md:text-[26px] lg:text-[36px] xl:text-[52px] text-[#302348] font-bold ">
              Automate your <span className="text-[#FFFFFF]">invoicing</span>{" "}
              process
            </p>
            <p className=" text-[14px] md:text-[18px] mb-7 md:mb-12">
              Save time and reduce manual errors by automating your invoicing.
              track payments, send reminders and get paid quick.
            </p>
            {/* <div className="mt-5 flex items-center gap-5">
              <p>See more</p>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2.8125C12.5895 2.8125 10.2332 3.52728 8.22899 4.86646C6.22477 6.20564 4.66267 8.10907 3.74022 10.336C2.81778 12.563 2.57643 15.0135 3.04668 17.3777C3.51694 19.7418 4.67769 21.9134 6.38214 23.6179C8.08659 25.3223 10.2582 26.4831 12.6223 26.9533C14.9865 27.4236 17.437 27.1822 19.664 26.2598C21.8909 25.3373 23.7944 23.7752 25.1335 21.771C26.4727 19.7668 27.1875 17.4105 27.1875 15C27.1841 11.7687 25.899 8.67076 23.6141 6.3859C21.3292 4.10104 18.2313 2.81591 15 2.8125ZM15 25.3125C12.9604 25.3125 10.9666 24.7077 9.27069 23.5745C7.5748 22.4414 6.25303 20.8308 5.4725 18.9464C4.69197 17.0621 4.48775 14.9886 4.88566 12.9881C5.28357 10.9877 6.26574 9.15019 7.70797 7.70796C9.1502 6.26573 10.9877 5.28356 12.9881 4.88565C14.9886 4.48774 17.0621 4.69196 18.9464 5.47249C20.8308 6.25302 22.4414 7.5748 23.5745 9.27068C24.7077 10.9666 25.3125 12.9604 25.3125 15C25.3094 17.7341 24.2219 20.3553 22.2886 22.2886C20.3553 24.2219 17.7341 25.3094 15 25.3125ZM20.3508 14.3367C20.438 14.4238 20.5071 14.5272 20.5543 14.641C20.6015 14.7548 20.6257 14.8768 20.6257 15C20.6257 15.1232 20.6015 15.2452 20.5543 15.359C20.5071 15.4728 20.438 15.5762 20.3508 15.6633L16.6008 19.4133C16.4249 19.5892 16.1863 19.688 15.9375 19.688C15.6887 19.688 15.4501 19.5892 15.2742 19.4133C15.0983 19.2374 14.9995 18.9988 14.9995 18.75C14.9995 18.5012 15.0983 18.2626 15.2742 18.0867L17.4246 15.9375H10.3125C10.0639 15.9375 9.82541 15.8387 9.64959 15.6629C9.47378 15.4871 9.375 15.2486 9.375 15C9.375 14.7514 9.47378 14.5129 9.64959 14.3371C9.82541 14.1613 10.0639 14.0625 10.3125 14.0625H17.4246L15.2742 11.9133C15.0983 11.7374 14.9995 11.4988 14.9995 11.25C14.9995 11.0012 15.0983 10.7626 15.2742 10.5867C15.4501 10.4108 15.6887 10.312 15.9375 10.312C16.1863 10.312 16.4249 10.4108 16.6008 10.5867L20.3508 14.3367Z"
                  fill="#353131"
                />
              </svg>
            </div> */}
          </div>
          <div className="absolute bottom-0 right-0">
            <div className="relative">
              <img
                src={frame1}
                alt=""
                className="absolute -top-6 right-4 lg:right-16 h-[80px] md:h-[140px] lg:h-[220px] xl:h-[220px]"
              />
              <img
                src={frame2}
                alt=""
                className=" bottom-0 right-4 lg:right-16 h-[80px] md:h-[140px] lg:h-[220px] xl:h-[220px]"
              />
            </div>
          </div>
        </section>
        <section className="mt-10 w-full overflow-hidden relative bg-[#FFBD39] px-4 md:px-[28px] lg:px-[42px] xl:px-[86px] py-5 md:py-7 lg:py-9 xl:py-14 ">
          <div className="mb-9 lg:mb-[62px] xl:mb-[86px] w-full md:w-[65%] lg:w-[60%] xl:w-[50%] z-10">
            <img
              src="/image/coin.png"
              alt=""
              className="absolute top-[50%] right-[40%]  h-[40px] md:h-[60px] lg:h-[80px] xl:h-[110px]"
            />

            <p className="text-[28px] md:text-[36px] lg:text-[48px] xl:text-[64px] font-bold ">
              Multi-Account and Currency
            </p>
            <p className="text-[14px] lg-[16px] z-10">
              Manage multiple accounts, currencies and cards in one place,
              without switching platform.
            </p>
          </div>

          {/* <div>
            <button className="flex items-center gap-2">
              <p className="text-[14px] lg:text-[16px] font-semibold">
                See more
              </p>{" "}
              <svg
                width="20"
                height="20"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 2.8125C12.5895 2.8125 10.2332 3.52728 8.22899 4.86646C6.22477 6.20564 4.66267 8.10907 3.74022 10.336C2.81778 12.563 2.57643 15.0135 3.04668 17.3777C3.51694 19.7418 4.67769 21.9134 6.38214 23.6179C8.08659 25.3223 10.2582 26.4831 12.6223 26.9533C14.9865 27.4236 17.437 27.1822 19.664 26.2598C21.8909 25.3373 23.7944 23.7752 25.1335 21.771C26.4727 19.7668 27.1875 17.4105 27.1875 15C27.1841 11.7687 25.899 8.67076 23.6141 6.3859C21.3292 4.10104 18.2313 2.81591 15 2.8125ZM15 25.3125C12.9604 25.3125 10.9666 24.7077 9.27069 23.5745C7.5748 22.4414 6.25303 20.8308 5.4725 18.9464C4.69197 17.0621 4.48775 14.9886 4.88566 12.9881C5.28357 10.9877 6.26574 9.15019 7.70797 7.70796C9.1502 6.26573 10.9877 5.28356 12.9881 4.88565C14.9886 4.48774 17.0621 4.69196 18.9464 5.47249C20.8308 6.25302 22.4414 7.5748 23.5745 9.27068C24.7077 10.9666 25.3125 12.9604 25.3125 15C25.3094 17.7341 24.2219 20.3553 22.2886 22.2886C20.3553 24.2219 17.7341 25.3094 15 25.3125ZM20.3508 14.3367C20.438 14.4238 20.5071 14.5272 20.5543 14.641C20.6015 14.7548 20.6257 14.8768 20.6257 15C20.6257 15.1232 20.6015 15.2452 20.5543 15.359C20.5071 15.4728 20.438 15.5762 20.3508 15.6633L16.6008 19.4133C16.4249 19.5892 16.1863 19.688 15.9375 19.688C15.6887 19.688 15.4501 19.5892 15.2742 19.4133C15.0983 19.2374 14.9995 18.9988 14.9995 18.75C14.9995 18.5012 15.0983 18.2626 15.2742 18.0867L17.4246 15.9375H10.3125C10.0639 15.9375 9.82541 15.8387 9.64959 15.6629C9.47378 15.4871 9.375 15.2486 9.375 15C9.375 14.7514 9.47378 14.5129 9.64959 14.3371C9.82541 14.1613 10.0639 14.0625 10.3125 14.0625H17.4246L15.2742 11.9133C15.0983 11.7374 14.9995 11.4988 14.9995 11.25C14.9995 11.0012 15.0983 10.7626 15.2742 10.5867C15.4501 10.4108 15.6887 10.312 15.9375 10.312C16.1863 10.312 16.4249 10.4108 16.6008 10.5867L20.3508 14.3367Z"
                  fill="#353131"
                />
              </svg>
            </button>
          </div> */}

          <img
            src="/image/coin.png"
            alt=""
            className="absolute -top-8 left-4 lg:right-16 h-[40px] md:h-[60px] lg:h-[80px] xl:h-[110px]"
          />
          <img
            src="/image/coin.png"
            alt=""
            className="absolute top-0 right-4 lg:right-16 h-[40px] md:h-[60px] lg:h-[80px] xl:h-[110px]"
          />
          <img
            src="/image/earth.png"
            alt=""
            className="absolute bottom-0 right-4 lg:right-16 h-[80px] md:h-[140px] lg:h-[220px] xl:h-[220px]"
          />
        </section>
        <section className="mt-10 md:mt-18 w-full rounded-xl overflow-hidden  bg-[#F9F9F9] px-4 md:px-[28px] lg:px-[42px] xl:px-[86px] py-5 md:py-7 lg:py-9 xl:py-14 ">
          <div className="flex flex-col-reverse md:flex-row gap-[36px] md:gap-[48px]">
            <div className="w-full md:w-[50%]">
              <img
                src="/image/groupImage.png"
                alt=""
                className=" h-[260px] md:h-[260px] lg:h-[300px] xl:h-[360px] mx-auto"
              />
            </div>
            <div className="w-full md:w-[50%]">
              <p className="text-[20px] md:text-[24px] mb-[21px] lg:text-[28px] xl:text-[32px] text-[#302348] font-bold ">
                Payment Solutions For{" "}
                <span className="text-[#3B6896]">Business</span>{" "}
              </p>

              <p className="text-[14px] mb-3 ">
                Our Services suit all Kinds of Business and All sizes, providing
                tailored support for;
              </p>

              <ul className="flex flex-col gap-2">
                <li className="flex items-center  gap-2">
                  <Star size="18" color="#3B6896" />
                  <p className="text-[14px] md:text-[16px] font-normal ">
                    Small startups to Large enterprises
                  </p>
                </li>
                <li className="flex items-center  gap-2">
                  <Star size="18" color="#3B6896" />
                  <p className="text-[14px] md:text-[16px] font-normal ">
                    Local Shops to Global coorporations
                  </p>
                </li>
                <li className="flex items-center  gap-2">
                  <Star size="18" color="#3B6896" />
                  <p className="text-[14px] md:text-[16px] font-normal ">
                    Online Marketplaces to brick-and-mortar stores
                  </p>
                </li>
                <li className="flex items-center  gap-2">
                  <Star size="18" color="#3B6896" />
                  <p className="text-[14px] md:text-[16px] font-normal ">
                    Service based to Product-based industries
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="mt-10 md:mt-18 w-full rounded-xl overflow-hidden  bg-[#F9F9F9] px-4 md:px-[28px] lg:px-[42px] xl:px-[86px] py-5 md:py-7 lg:py-9 xl:py-14 ">
          <p className="text-[20px] md:text-[24px] mb-[21px] lg:text-[28px] text-[#302348] font-bold ">
            More Key Feature
          </p>

          <div className="flex flex-col md:flex-row gap-[36px]">
            <div className="relative z-10 w-full md:w-[50%] rounded-[20px] h-[360px] md:h-[420px] xl:h-[500px] overflow-hidden py-[28px] md:py-[34px] lg:py-[42px] px-[20px] md:px-[28px]">
              <img
                src="/image/feature1.jpg"
                alt=""
                className="-z-10 absolute left-0 top-0 w-full h-full object-cover"
              />
              <div className="h-full flex flex-col justify-between ">
                <p className="text-white md:text-[18px] font-semibold">
                  Virtual Account
                </p>

                <div>
                  <p className="text-[14px] md:text-[16px] text-white w-[70%] mb-4">
                    Payments and transactions can be made directly from your
                    business account.
                  </p>

                  {/* <button className="flex items-center gap-2">
                    <p className="text-[14px] lg:text-[16px] font-semibold text-white">
                      See more
                    </p>{" "}
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 2.8125C12.5895 2.8125 10.2332 3.52728 8.22899 4.86646C6.22477 6.20564 4.66267 8.10907 3.74022 10.336C2.81778 12.563 2.57643 15.0135 3.04668 17.3777C3.51694 19.7418 4.67769 21.9134 6.38214 23.6179C8.08659 25.3223 10.2582 26.4831 12.6223 26.9533C14.9865 27.4236 17.437 27.1822 19.664 26.2598C21.8909 25.3373 23.7944 23.7752 25.1335 21.771C26.4727 19.7668 27.1875 17.4105 27.1875 15C27.1841 11.7687 25.899 8.67076 23.6141 6.3859C21.3292 4.10104 18.2313 2.81591 15 2.8125ZM15 25.3125C12.9604 25.3125 10.9666 24.7077 9.27069 23.5745C7.5748 22.4414 6.25303 20.8308 5.4725 18.9464C4.69197 17.0621 4.48775 14.9886 4.88566 12.9881C5.28357 10.9877 6.26574 9.15019 7.70797 7.70796C9.1502 6.26573 10.9877 5.28356 12.9881 4.88565C14.9886 4.48774 17.0621 4.69196 18.9464 5.47249C20.8308 6.25302 22.4414 7.5748 23.5745 9.27068C24.7077 10.9666 25.3125 12.9604 25.3125 15C25.3094 17.7341 24.2219 20.3553 22.2886 22.2886C20.3553 24.2219 17.7341 25.3094 15 25.3125ZM20.3508 14.3367C20.438 14.4238 20.5071 14.5272 20.5543 14.641C20.6015 14.7548 20.6257 14.8768 20.6257 15C20.6257 15.1232 20.6015 15.2452 20.5543 15.359C20.5071 15.4728 20.438 15.5762 20.3508 15.6633L16.6008 19.4133C16.4249 19.5892 16.1863 19.688 15.9375 19.688C15.6887 19.688 15.4501 19.5892 15.2742 19.4133C15.0983 19.2374 14.9995 18.9988 14.9995 18.75C14.9995 18.5012 15.0983 18.2626 15.2742 18.0867L17.4246 15.9375H10.3125C10.0639 15.9375 9.82541 15.8387 9.64959 15.6629C9.47378 15.4871 9.375 15.2486 9.375 15C9.375 14.7514 9.47378 14.5129 9.64959 14.3371C9.82541 14.1613 10.0639 14.0625 10.3125 14.0625H17.4246L15.2742 11.9133C15.0983 11.7374 14.9995 11.4988 14.9995 11.25C14.9995 11.0012 15.0983 10.7626 15.2742 10.5867C15.4501 10.4108 15.6887 10.312 15.9375 10.312C16.1863 10.312 16.4249 10.4108 16.6008 10.5867L20.3508 14.3367Z"
                        fill="#fff"
                      />
                    </svg>
                  </button> */}
                </div>
              </div>
            </div>
            <div className="relative z-10 w-full md:w-[50%] rounded-[20px] h-[360px] md:h-[420px] xl:h-[500px] overflow-hidden py-[28px] md:py-[34px] lg:py-[42px] px-[20px] md:px-[28px]">
              <img
                src="/image/feature2.png"
                alt=""
                className="-z-10 absolute left-0 top-0 w-full h-full object-cover"
              />
              <div className="h-full flex flex-col justify-between ">
                <p className="text-white md:text-[18px] font-semibold">
                  Bulk Transfer
                </p>

                <div>
                  <p className="text-[14px] md:text-[16px] text-white w-[70%] mb-4">
                    Make bulk payouts or manage transfers easily through our API
                    or dashboard.
                  </p>

                  {/* <button className="flex items-center gap-2">
                    <p className="text-[14px] lg:text-[16px] font-semibold text-white">
                      See more
                    </p>{" "}
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 2.8125C12.5895 2.8125 10.2332 3.52728 8.22899 4.86646C6.22477 6.20564 4.66267 8.10907 3.74022 10.336C2.81778 12.563 2.57643 15.0135 3.04668 17.3777C3.51694 19.7418 4.67769 21.9134 6.38214 23.6179C8.08659 25.3223 10.2582 26.4831 12.6223 26.9533C14.9865 27.4236 17.437 27.1822 19.664 26.2598C21.8909 25.3373 23.7944 23.7752 25.1335 21.771C26.4727 19.7668 27.1875 17.4105 27.1875 15C27.1841 11.7687 25.899 8.67076 23.6141 6.3859C21.3292 4.10104 18.2313 2.81591 15 2.8125ZM15 25.3125C12.9604 25.3125 10.9666 24.7077 9.27069 23.5745C7.5748 22.4414 6.25303 20.8308 5.4725 18.9464C4.69197 17.0621 4.48775 14.9886 4.88566 12.9881C5.28357 10.9877 6.26574 9.15019 7.70797 7.70796C9.1502 6.26573 10.9877 5.28356 12.9881 4.88565C14.9886 4.48774 17.0621 4.69196 18.9464 5.47249C20.8308 6.25302 22.4414 7.5748 23.5745 9.27068C24.7077 10.9666 25.3125 12.9604 25.3125 15C25.3094 17.7341 24.2219 20.3553 22.2886 22.2886C20.3553 24.2219 17.7341 25.3094 15 25.3125ZM20.3508 14.3367C20.438 14.4238 20.5071 14.5272 20.5543 14.641C20.6015 14.7548 20.6257 14.8768 20.6257 15C20.6257 15.1232 20.6015 15.2452 20.5543 15.359C20.5071 15.4728 20.438 15.5762 20.3508 15.6633L16.6008 19.4133C16.4249 19.5892 16.1863 19.688 15.9375 19.688C15.6887 19.688 15.4501 19.5892 15.2742 19.4133C15.0983 19.2374 14.9995 18.9988 14.9995 18.75C14.9995 18.5012 15.0983 18.2626 15.2742 18.0867L17.4246 15.9375H10.3125C10.0639 15.9375 9.82541 15.8387 9.64959 15.6629C9.47378 15.4871 9.375 15.2486 9.375 15C9.375 14.7514 9.47378 14.5129 9.64959 14.3371C9.82541 14.1613 10.0639 14.0625 10.3125 14.0625H17.4246L15.2742 11.9133C15.0983 11.7374 14.9995 11.4988 14.9995 11.25C14.9995 11.0012 15.0983 10.7626 15.2742 10.5867C15.4501 10.4108 15.6887 10.312 15.9375 10.312C16.1863 10.312 16.4249 10.4108 16.6008 10.5867L20.3508 14.3367Z"
                        fill="#fff"
                      />
                    </svg>
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mt-10 md:mt-18 w-full rounded-xl overflow-hidden   px-4 md:px-[28px] lg:px-[42px] xl:px-[86px] py-5 md:py-7 lg:py-9 xl:py-14 ">
          <h2 className="text-center text-[22px] md:text-[26px] lg:text-[34px] xl:text-[40px] text-[#5B5A5A] ">
            Create your first{" "}
            <span className="font-semibold text-[#3B6896]">
              Virtual Account
            </span>{" "}
            today
          </h2>
          <p className="text-[14px] md:text-[15px] font-medium text-center">
            Generate virtual accounts specific to each customer for one time or
            recurring payments
          </p>

          <div className="flex  items-center gap-5 mt-8 md:mt-10 justify-center ">
            <Link
              to="https://business.vant.ng/login"
              rel="noreferrer"
              target="_blank"
              className="w-[150px] h-[40px] text-center flex justify-center items-center  md:h-[50px] md:w-[200px] text-[14px] md:text-[15px] bg-[#3B6896] text-[#fff] font-normal rounded-[40px] hover:bg-[#2F5275]"
            >
              Create Account
            </Link>
            <a
              href="mailto: support@vantapp.com"
              className="w-[150px] py-[14px] md:py-[18px] md:w-[200px] text-[14px] md:text-[15px] bg-[#F7F7F7] text-[#5B5A5A] font-normal rounded-[40px] text-center  "
            >
              Contact Support
            </a>
          </div>
        </section>
        <section className="mt-10  md:mt-18 mb-12 md:mb-18 w-full bg-gradient-to-r from-[#477CB1FF] to-[#3B6896] rounded-xl flex flex-col items-center overflow-hidden   px-4 md:px-[28px] lg:px-[42px] xl:px-[86px] py-5 md:py-7 lg:py-9 xl:py-14 ">
          <button className="w-[150px] mx-auto  h-[40px]  md:h-[50px]  md:w-[200px] text-[14px] md:text-[15px] content-center bg-[#FFFFFF45] text-[#fff] font-normal rounded-[40px] hover:bg-[#2F5275]">
            For Developers
          </button>

          <p className=" mt-10 md:mt-12 text-[18px] md:text-[24px] xl:text-[28px] font-medium text-center  text-white">
            Our well documented <span className="font-semibold">APIs</span>{" "}
            provides you with all you need to build your custom{" "}
            <span className="font-semibold">Projects and Products.</span>
          </p>

          <Link
            to="https://vantpapi.readme.io/reference/getting-started-with-your-api"
            target="_blank"
            rel="noreferrer"
            className="w-[250px] mt-10 md:mt-12 mb-3 mx-auto h-[45px] md:h-[50px] md:w-[280px] text-[14px] flex justify-center items-center md:text-[15px] border-white border text-[#fff] font-normal rounded-[40px] hover:bg-[#2F5275]"
          >
            Read our documentation
          </Link>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Bussiness;
