import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="px-[16px] md:px-[60px]  lg:px-[120px] ">
      <div className="pb-[30px] mb-[30px] md:pb-[20px] md:mb-[14px] md:mb-[20px] border-b">
        <img
          src="/image/VantLogo.png"
          alt=""
          className="mb-[40px] md-[60px] lg-[80px] w-[107px] lg:w-[147px] object-contain"
        />
        <div className="flex justify-between">
          <div className="flex flex-wrap gap-[30px] md:gap-[50px] justify-between w-[80%] md:w-full">
            <div>
              <h3 className="text-[16px] font-semibold mb-[14px] md:mb-[16px] ">
                Company
              </h3>{" "}
              <ul className="flex flex-col gap-[13px] md:gap-[18px] text-[14px]">
                <Link className="cursor-pointer" to="/company">
                  <li className=" text-[#373738]">About us</li>
                </Link>
                <a href="mailto: support@vantapp.com">
                  <li className=" text-[#373738]">Contact us</li>
                </a>
                <Link className="cursor-pointer" to="/allblog">
                  <li className=" text-[#373738]">Blog</li>
                </Link>
                <Link to="/faq">
                  <li className=" text-[#373738]">FAQs</li>
                </Link>
              </ul>
            </div>
            {/* <div>
              <h3 className="text-[18px] md:text-[20px] lg:text-[24px] font-semibold mb-[30px] md-[40px]">
                Business
              </h3>{" "}
              <ul className="flex flex-col gap-[16px] md:gap-[24px]">
                <li className="text-[18px] md:text-[20px] lg:text-[24px] text-[#373738]">
                  Biz Accounts{" "}
                </li>
                <li className="text-[18px] md:text-[20px] lg:text-[24px] text-[#373738]">
                  CAC Reg{" "}
                </li>
                <li className="text-[18px] md:text-[20px] lg:text-[24px] text-[#373738]">
                  Mgt Tools{" "}mb-[14px] md:mb-[20px]
                </li>
              </ul>
            </div> */}
            <div>
              <h3 className="text-[16px] font-semibold mb-[14px] md:mb-[16px] ">
                Developers{" "}
              </h3>{" "}
              <ul className="flex flex-col gap-[13px] md:gap-[18px] text-[14px] ">
                <Link
                  className="cursor-pointer"
                  to="https://vantapi.readme.io/reference/getting-started-with-your-api"
                  traget="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <li className=" text-[#373738]">API Doc </li>
                </Link>
                {/* <li className=" text-[#373738]">Integrations </li>
                <li className=" text-[#373738]">Status</li> */}
              </ul>
            </div>
            <div>
              <h3 className="text-[16px] font-semibold mb-[14px] md:mb-[16px] ">
                Legal
              </h3>{" "}
              <ul className="flex flex-col gap-[13px] md:gap-[18px] text-sm">
                <Link className="cursor-pointer" to="/">
                  <li className=" text-[#373738]">Terms of Service </li>
                </Link>
                <Link className="cursor-pointer" to="/">
                  <li className=" text-[#373738]">Privacy Policy </li>
                </Link>
                <li className=" text-[#373738]">Security</li>
              </ul>
            </div>
            <div>
              <h3 className="text-[16px] font-semibold mb-[14px] md:mb-[16px] ">
                Our Office:
              </h3>{" "}
              <ul className="flex flex-col gap-[13px] md:gap-[18px] text-sm">
                <li className=" text-[#373738]">
                  <strong>United States</strong> <br />
                  212 N. 2nd St. STE 100 <br />
                  Richmond, Kentucky, United States.
                </li>
                <li className=" text-[#373738]">
                  <strong>Nigeria</strong> <br />
                  22 Glover Rd, Ikoyi, Lagos 106104,
                  <br />
                  Lagos, Nigeria
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-[16px] font-semibold mb-[14px] md:mb-[16px] ">
                Contact
              </h3>{" "}
              <ul className="flex flex-col gap-[13px] md:gap-[18px] text-sm">
                <li className=" text-[#373738]">
                  <strong>Email</strong> <br />
                  <a href="mailto:hello@vant.com.ng">hello@vant.com.ng</a>
                 
                </li>
                <li className=" text-[#373738]">
                  <strong>Phone</strong> <br />
                  <a href="tel:+2349135792534">+234{" "} 913{" "} 579{" "} 2534</a><br />
                  <a href="tel:+13412412171">+1 {" "}341 {" "} 241 {" "} 2171</a>

                  
                </li>
              </ul>
            </div>
          </div>
          <div className="gap-4 items-center md:hidden flex flex-col">
            <Link
              to="https://www.linkedin.com/company/vantafrica"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/image/linkedin.png" alt="" className="h-[24px]" />
            </Link>
            <Link
              to="https://x.com/vantappafrica?s=21"
              rel="noreferrer"
              target="_blank"
            >
              <img src="/image/x.png" alt="" className="h-[24px]" />
            </Link>
            <Link
              to="https://instagram.com/vantappafrica?igshid=MzRIODBiNWFIZA=="
              rel="noreferrer"
              target="_blank"
            >
              <img src="/image/insta.png" alt="" className="h-[24px]" />
            </Link>
          </div>
        </div>

        <div className="flex justify-between">
          <p className="mt-[40px] text-[12px] ">.</p>
          <div className="gap-4 items-center hidden md:flex">
            <Link
              to="https://www.linkedin.com/company/vantafrica"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/image/linkedin.png" alt="" />
            </Link>
            <Link
              to="https://www.linkedin.com/company/vantafrica"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/image/x.png" alt="" />
            </Link>
            <Link
              to="https://www.linkedin.com/company/vantafrica"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/image/insta.png" alt="" />
            </Link>
          </div>
        </div>
      



        <div className="w-full flex text-xs md:text-sm items-center gap-2 mx-auto text-bold">
          <Link
            to="https://services.ndpc.gov.ng/portal/?page=verify-c&d=dn36294120662&id=24054&sn=40fc0a2e43986e15e06547213854b27b&t=dp_registration&tp=nwp_eosic"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="./image/ndpr.PNG"
              alt="ndpr"
              className=" h-[70px] md:h-[85px]"
            />
          </Link>{" "}
          NDPR Compliant
        </div>
      </div>
      <p className="pb-[40px] text-[12px]">
        <strong>Disclaimer:</strong>Disclaimer: Vant operates as a financial
        technology company and does not provide banking services or hold
        depositor funds. All financial transactions facilitated by Vant are
        conducted in partnership with licensed financial institutions in their
        respective jurisdictions. Vant does not engage in traditional banking
        activities. By accessing or using Vant’s website and services, you
        acknowledge and agree that Vant is not a bank, and all services are
        provided through third-party partners.
        <br />
        <br />
        <strong>Vant</strong> is a trade name of Advant Capitol Ltd, a private
        limited company incorporated in Nigeria. In the United States,
        <strong> Vant</strong> is registered as
        <strong> Vant Inc.</strong> in the state of Delaware.{" "}
        <strong>Vant</strong> is a licensed money lender and a licensed
        Multi-purpose cooperative society in Nigeria.
        <br /> <br />
        <strong>Vant Inc</strong> is a registered MSB with FinCEN United
        States(USA). Deposits are FDIC insured through our banking partners.
        <br /> <br />
        Vant™️ is a registered trademark representing proprietary financial
        management software solutions. This trademark ensures the integrity,
        authenticity, and exclusivity of Vant’s offerings while underscoring its
        commitment to providing secure and reliable financial technology
        services. <br /> <br />
        Copyright ©️ 2025 Vant Inc. All rights reserved.
        {/* Advant Capitol is a financial technology company that is duly registered
        with the Corporate Affairs Commission of Nigeria (RC: 1750394). Our
        office is located at 39 Govt Building, Isale Igbehin, Abeokuta, Ogun.
        Our savings scheme is also duly registered under the Co-operative
        Societies Laws of Ogun State with registration number OGSCS 12701 as
        Advant Capitol Progressive Staff (Isale Igbehin) Multipurpose
        Cooperative Society Limited. Funds are held in trust by a registered
        trustee, a company that is registered and regulated by the Security and
        Exchange Commission (SEC). Our activities and partnerships are in line
        with the best ethical practices and the laws of the Federal Republic of
        Nigeria. Please note that any unauthorized redistribution or
        reproduction of any copyrighted materials on this website is strictly
        prohibited. Other product and company names are trademarks of their
        respective owners. */}
      </p>
    </div>
  );
};

export default Footer;
