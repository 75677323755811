import { CloseCircle, Danger, Lock, Sms } from "iconsax-react";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { decryptaValue } from "../utils";
import Loader from "../components/Loader";
import Modal from "../components/Modal";

const InvoicePay = () => {
  const { slug } = useParams();
  const [details, setDetails] = useState(null);
  const [amount, setAmount] = useState();
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const navigate = useNavigate();
   const [error, setError] = useState("");
    const [isValid, setIsValid] = useState(false);
  
    // Validate the input as user types
    const handleAmountChange = (e) => {
      const inputValue = e.target.value;
  
      // Only allow digits, one decimal point, and nothing else
      // This provides immediate feedback while typing
      if (inputValue === "" || /^\d*\.?\d*$/.test(inputValue)) {
        setAmount(inputValue);
  
        // Clear error if the field is empty (for better UX)
        if (inputValue === "") {
          setError("");
          setIsValid(false);
          return;
        }
  
        // Additional validations
        const numericValue = parseFloat(inputValue);
  
        if (isNaN(numericValue)) {
          setError("Please enter a valid amount");
          setIsValid(false);
        } else if (numericValue <= 0) {
          setError("Amount must be greater than zero");
          setIsValid(false);
        } else {
          // Check decimal places
          const decimalParts = inputValue.split(".");
          if (decimalParts.length > 1 && decimalParts[1].length > 2) {
            setError("Amount cannot have more than 2 decimal places");
            setIsValid(false);
          } else {
            setError("");
            setIsValid(true);
          }
        }
      }
    };
  //production server
   //const API_BASE_URL = 'https://dev.vantapp.com/api/partner-business';

  //live server
  const API_BASE_URL = "https://api.vantapp.com/api/partner-business";

  // Use slug to fetch product details
  const fetchProductDetails = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/public/invoices/${slug}`
      );
      const result = await response.json();
      setDetails(result?.data);
      setAmount(result?.data?.total_amount);
      setName(result?.data?.customer?.name);
      setEmail(result?.data?.customer?.email);

      if (result?.status === "error") {
        setErrorModal(true);
        setErrorMessage(result?.message);
      }
      setIsLoading(false);
    } catch (error) {
      //
    }
  };


  React.useEffect(() => {
    fetchProductDetails();
  }, [slug]);

  const apiUrl = `${API_BASE_URL}/public/invoices/${slug}/checkout`;
  const requestBody = {
    amount: parseFloat(amount),
    email: email,
    name: name,
  };

  const requestCheckout = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(apiUrl, {
        method: "POST", // HTTP method
        headers: {
          "Content-Type": "application/json", // Specify JSON format
        },
        // body: JSON.stringify(requestBody), // Convert body object to JSON string
      });

      //   // Parse JSON response
      //   if (!response.ok) {
      //     throw new Error(`HTTP error! Status: ${response.status}`);
      //   }

      const data = await response.json(); // Handle JSON response
      const decryptRes = JSON.parse(decryptaValue(data?.data));
      console.log("pay data====>>>", decryptRes);
      setIsLoading(false);
      navigate("/checkout", { state: { payData: decryptRes , type: "invoice" } });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div className="min-h-screen bg-[#F2F2F2] jost w-full relative  z-10 flex flex-col items-center">
      {isLoading && <Loader />}

      <img
        src="/image/vector.png"
        alt="bg"
        className="absolute top-0 left-0 -z-10 h-full w-full object-cover "
      />

      <div className="w-[90%] sm:w-[400px] md:w-[486px] mt-12">
        <div className=" bg-white border rounded-[16px] rounded-tr-[16px] pt-5 pb-3 md:py-6 px-4 md:px-6">
          <img
            src="/image/VantLogo.png"
            alt="Logo"
            className=" h-[40px] w-[90px] md:h-[50px] md:w-[107px] mx-auto"
          />

       
          <p className="text-[14px] text-gray-600 mt-3 jost ">
            Description: {details?.title}
          </p>

          <form onSubmit={requestCheckout} className="mt-6">
            <div className="mb-[16px] md:mb-[20px]">
              <label className="text-[14px] text-[#667185] leading-[20px]   mb-[8px] md:mb-[16px]">
                Amount
              </label>
              <div className=" relative   flex items-center">
                <span className="text-[14px] text-[#667185] leading-[20px] absolute left-[16px] pr-2  border-[#D0D5DD] border-r-[0.2px]">
                  NGN
                </span>
                <input
                  type="text"
                  placeholder=""
                  className="w-full h-[40px] pl-[62px] pr-[8px] py-[12px] text-[16px] text-[#344054] leading-[20px]  placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#26ae5f] focus:border-[#26ae5f] "
                  required
                  name="amount"
                  value={amount}
                  onChange={handleAmountChange}
                  inputMode="decimal"
                  aria-describedby="amount-error"
                  disabled={details?.is_amount_fixed === 1}
                />
              </div>
              {error && (
                <p className="mt-2 text-xs text-red-600" id="amount-error">
                  {error}
                </p>
              )}
            </div>
            <div className="mb-[16px] md:mb-[20px]">
              <label className="text-[14px] text-[#667185] leading-[20px]   mb-[8px] md:mb-[16px]">
                Full Name
              </label>
              <div className=" relative   flex items-center">
                <input
                  type="text"
                  placeholder=""
                  className="w-full h-[40px] pl-[16px] pr-[8px] py-[12px] text-[16px] text-[#344054] leading-[20px]  placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#26ae5f] focus:border-[#26ae5f] "
                  required
                  name="name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="mb-[16px] md:mb-[20px] w-full">
              <label className="text-[14px] md:text-[14px] font-normal leading-[24px]  text-[#667185] mb-[8px]">
                Email
              </label>
              <div className=" relative    flex items-center w-full">
                <Sms
                  size="16"
                  color="#98A2B3"
                  className="absolute left-[16px]"
                />
                <CloseCircle
                  size="16"
                  color="#98A2B3"
                  className="absolute right-[16px] cursor-pointer"
                  // onClick={() => deleteInput("email")}
                />

                <input
                  type="email"
                  placeholder="Enter email address"
                  className="w-full h-[40px] pl-[44px] py-[12px] text-[16px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#26ae5f] focus:border-[#26ae5f] "
                  required
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>

            <button
              type="submit"
              className="w-full py-[10px] text-center text-white bg-[#26ae5f] rounded-[8px] flex items-center justify-center mb-[20px] md:mb-[32px]"
            >
              <p className="text-sm font-medium leading-[20px]">Pay</p>
              {isLoading && <ClipLoader color={"white"} size={20} />}
            </button>
          </form>
        </div>
        <div className=" mt-8 flex items-center justify-center gap-[4px] text-[10px] text-gray-500">
          <Lock size={12} color="#000" /> <p>Powered & Secured by</p>{" "}
          <img
            src="/image/VantLogo.png"
            alt="Logo"
            className=" h-[10px] w-[20px]"
          />
        </div>
      </div>

      <Modal isOpen={errorModal}>
        <div className="inline-block overflow-hidden text-left relative align-bottom transition-all transform bg-[white] rounded-lg shadow-xl w-[90%] sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
          <div className="mt-4 flex flex-col items-center mx-5 jost  ">
            <p className="text-[14px] leading-[13px] jost  text-gray-700  ">
              Link Error
            </p>
            <div className="mx-auto mt-8">
              <Danger size="24" color="#f45f5f" />
            </div>

            <div classsName="text-center">
              <p className="text-[14px] animate-pulse leading-[13px] jost  text-center text-[#f45f5f] mt-6  mb-8">
                {errorMessage}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InvoicePay;
